import React from "react";
import Card from "./Card";

const PersonCard = ({ person, onClick }) => {
  return (
    <Card onClick={onClick}>
      <div className="row">
        <span>{person?.name}</span>
        <span> {person?.email}</span>
      </div>
    </Card>
  );
};

export default PersonCard;
