import React from "react";

const DetailRow = ({ label, value }) => {
  return (
    <div className="row">
      <strong>{label}:</strong>
      <span>{value}</span>
    </div>
  );
};

export default DetailRow;
