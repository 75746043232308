import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "../components/Card";
import DetailRow from "../components/DetailRow";
import Page from "../components/Page";

const PersonDetailsPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();
  const [person, setPerson] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    // fetch(`https://jsonplaceholder.typicode.com/users/${id}`)
    //   .then((response) => response.json())
    //   .then((data) => setPerson(data))
    //   .catch((error) => setPerson(state?.person));
    if (state?.person) setPerson(state?.person);

    return () => {
      setPerson([]);
    };
  }, [state]);

  return (
    <Page {...props}>
      <div className="row" style={{ marginBottom: 10 }}>
        &nbsp;
        <Link to="#" onClick={() => navigate(-1)}>
          Go Back
        </Link>
      </div>
      <Card>
        <DetailRow label="Name" value={person?.name} />
        <DetailRow label="Username" value={person?.username} />
        <DetailRow label="Email" value={person?.email} />
        <DetailRow label="Address" value={person?.address?.street} />
        <DetailRow label="City" value={person?.address?.city} />
        <DetailRow label="Zip Code" value={person?.address?.zipcode} />
      </Card>
    </Page>
  );
};

export default PersonDetailsPage;
