import React from "react";

const Input = ({ label, name, register, errors, required }) => (
  <div className="input-container">
    <label>{label}</label>
    <input {...register(name, { required })} />
    {errors[name] && <span className="error">This field is required</span>}
  </div>
);

export default Input;
