import React from "react";

const Page = ({ children, title, ...props }) => {
  return (
    <div className="container">
      <h1>{title}</h1>
      {children}
    </div>
  );
};

export default Page;
