import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Input from "../components/Input";
import Page from "../components/Page";
import PersonsContext from "../context/personsContext";

const AddPersonPage = ({ title }) => {
  const { persons, setPersons } = useContext(PersonsContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setPersons([...persons, data]);
    navigate(-1);
  };

  return (
    <Page title={title}>
      <div className="row" style={{ marginBottom: 10 }}>
        &nbsp;
        <Link to="#" onClick={() => navigate(-1)}>
          Go Back
        </Link>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Name"
          name="name"
          register={register}
          errors={errors}
          required
        />
        <Input
          label="Username"
          name="username"
          register={register}
          errors={errors}
          required
        />
        <Input
          label="Email"
          name="email"
          register={register}
          errors={errors}
          required
        />
        <Input
          label="Address"
          name="address"
          register={register}
          errors={errors}
        />
        <Input label="City" name="city" register={register} errors={errors} />
        <Input
          label="Zip Code"
          name="zipcode"
          register={register}
          errors={errors}
        />

        <input className="btn" type="submit" />
      </form>
    </Page>
  );
};

export default AddPersonPage;
