import React from "react";

const Card = ({ children, onClick }) => {
  return (
    <div
      className={`card ${onClick ? "cursor-pointer" : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
