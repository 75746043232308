import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import PersonsContext from "./context/personsContext";
import AddPersonPage from "./pages/AddPersonPage";
import NotFoundPage from "./pages/NotFoundPage";
import PersonDetailsPage from "./pages/PersonDetailsPage";
import PersonsPage from "./pages/PersonsPage";

function App() {
  const [persons, setPersons] = useState([]);
  const [loaded, setLoaded] = useState(false);

  return (
    <PersonsContext.Provider value={{ persons, setPersons, loaded, setLoaded }}>
      <Routes>
        <Route path="/" element={<Navigate to="/persons" />} />
        <Route path="/persons" element={<PersonsPage title="Directory" />} />
        <Route
          path="/persons/details/:id"
          element={<PersonDetailsPage title="Details" />}
        />
        <Route
          path="/persons/add"
          element={<AddPersonPage title="Add New Person" />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </PersonsContext.Provider>
  );
}

export default App;
