import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";
import PersonCard from "../components/PersonCard";
import PersonsContext from "../context/personsContext";

const PersonsPage = (props) => {
  const { persons, setPersons, loaded, setLoaded } = useContext(PersonsContext);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("First load");
    if (!loaded) {
      fetch("https://jsonplaceholder.typicode.com/users")
        .then((response) => response.json())
        .then((data) => setPersons(data))
        .catch((error) => console.error(error));

      setLoaded(true);
    }

    return () => {
      console.log("Leaving the directory page...");
    };
  }, []);

  const handleClick = (person) => (event) =>
    navigate(`/persons/details/${person?.id}`, { state: { person: person } });

  return (
    <Page {...props}>
      <button
        className="btn"
        type="button"
        onClick={() => navigate("/persons/add")}
      >
        Add New
      </button>

      {persons?.map((person, index) => (
        <PersonCard
          key={`person-${person.id}`}
          person={person}
          onClick={handleClick(person)}
        />
      ))}
    </Page>
  );
};

export default PersonsPage;
